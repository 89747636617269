// All properties should be getters to avoid issues with jest
export default {
  get host() {
    return process.env.LIKESHOP_FRONTEND_HOST || 'localhost';
  },
  get port() {
    return process.env.PORT;
  },
  get baseUrl() {
    return `${this.scheme}://${this.host}:${this.port}`;
  },
  get scheme() {
    return process.env.SCHEME || 'http';
  },
  get sentryDSN() {
    return process.env.VUE_APP_LIKESHOP_SENTRY_DSN;
  },
  get sentryRelease() {
    return process.env.VUE_APP_REVISION;
  },
  get storageKeys() {
    return {
      allowCookies: 'likeshop-allow-cookies',
    };
  },
};
