<template>
  <div class="likeshop-image-container">
    <Picture :url="media.imageUrl" :alt="altText" :size="mediaSize" :fit="objectFit" />
  </div>
</template>

<script>
import Picture from '@/components/media/Picture.vue';
import { imageApiFitValidation, imageApiSizeValidation } from '@/utils/imageApi';

export default {
  name: 'LikeShopItemImage',
  components: {
    Picture,
  },
  props: {
    media: { type: Object, default: null },
    objectFit: imageApiFitValidation,
    mediaSize: imageApiSizeValidation,
    defaultAltText: { type: String || null, default: null },
  },
  computed: {
    altText() {
      if (this.media?.title) {
        const { title } = this.media;
        return this.media.hasMultipleProducts ? `${title} and more` : title;
      }
      return this.defaultAltText;
    },
  },
};
</script>

<style scoped lang="scss">
.likeshop-image-container {
  height: 100%;
  width: 100%;
}
</style>
