import axios from 'axios';
import humps from 'humps';
import cloneDeep from 'lodash/cloneDeep';

export function createAxiosInstance(
  baseURL,
  options = {
    camelizeKeys: false,
    decamelizeRequests: false,
    decamelizeIgnoreKeys: null,
  }
) {
  const axOptions = cloneDeep(options);
  const ax = axios.create({ baseURL });
  ax.interceptors.request.use(
    (axConfig) => {
      if (axOptions.decamelizeRequests || axConfig?.decamelizeRequests) {
        const decamelizeConvert = (key, convert, convertOptions) =>
          axOptions.decamelizeIgnoreKeys?.includes(key) ? key : convert(key, convertOptions);
        axConfig.params = humps.decamelizeKeys(axConfig.params, decamelizeConvert);
        axConfig.data = humps.decamelizeKeys(axConfig.data, decamelizeConvert);
      }
      return axConfig;
    },
    (error) => Promise.reject(error)
  );
  ax.interceptors.response.use(
    (response) => {
      if (axOptions.camelizeKeys || response?.config?.camelizeKeys) {
        return humps.camelizeKeys(response);
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return ax;
}
