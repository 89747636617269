import config from '@/config';
import likeshop from '@/config/likeshop';

const { browserStorageNamespace } = config;

export function browserStorageSetItem(key, val) {
  try {
    localStorage.setItem(`${browserStorageNamespace}-${key}`, val);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
}

export function browserStorageGetItem(key) {
  let item = false;
  try {
    item = localStorage.getItem(`${browserStorageNamespace}-${key}`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
  return item;
}

export function getCookieConsentKey(platformType, socialMediaAccountName) {
  return `${platformType.toLowerCase()}-${socialMediaAccountName.toLowerCase()}-${
    likeshop.storageKeys.allowCookies
  }`;
}
