<template>
  <div></div>
</template>

<script>
const fbqScript = `
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '<PIXEL_ID>');
  fbq('track', 'PageView');
`;

export default {
  props: {
    pixelId: { type: [String, Number], default: null },
  },
  data: () => {
    return {
      unwatch: null,
    };
  },
  created() {
    this.unwatch = this.$watch('pixelId', this.init);
  },
  methods: {
    init(pixelId) {
      this.unwatch();
      if (!pixelId) return;

      const script = document.createElement('script');
      const noscript = document.createElement('noscript');
      const img = document.createElement('img');

      script.type = 'text/javascript';
      script.text = fbqScript.replace('<PIXEL_ID>', pixelId);
      img.setAttribute('height', '1');
      img.setAttribute('width', '1');
      img.setAttribute('style', 'display:none');
      img.setAttribute('src', `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`);
      noscript.appendChild(img);

      document.body.appendChild(script);
      document.body.appendChild(noscript);
    },
  },
};
</script>
