<template>
  <div class="skeleton-loader-container">
    <component :is="skeletonComponent" v-if="loading" v-bind="$attrs"></component>
    <slot v-else></slot>
  </div>
</template>

<script>
export const SKELETON_TYPES = {
  CircleSkeleton: {
    type: 'circle',
    component: () => import('@/components/skeleton/CircleSkeleton.vue'),
  },
  PictureSkeleton: {
    type: 'picture',
    component: () => import('@/components/skeleton/PictureSkeleton.vue'),
  },
};

const SKELETON_COMPONENTS = Object.keys(SKELETON_TYPES).reduce((acc, key) => {
  const results = acc;
  results[key] = SKELETON_TYPES[key].component;
  return results;
}, {});
const SKELETON_COMPONENT_TYPE_MAP = Object.keys(SKELETON_TYPES).reduce((acc, key) => {
  const results = acc;
  results[SKELETON_TYPES[key].type] = key;
  return results;
}, {});
export default {
  name: 'SkeletonLoader',
  components: {
    ...SKELETON_COMPONENTS,
  },
  props: {
    loading: { type: Boolean, default: false },
    type: {
      type: String,
      required: true,
      default: 'title',
      validator: (value) =>
        Object.keys(SKELETON_TYPES)
          .map((key) => SKELETON_TYPES[key].type)
          .includes(value),
    },
  },
  computed: {
    skeletonComponent() {
      return SKELETON_COMPONENT_TYPE_MAP[this.type];
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton-loader-container {
  display: contents;
}
</style>
