<template>
  <input
    ref="textInput"
    v-model="model"
    :type="type"
    :placeholder="placeholder"
    class="text-input"
    v-on="inputListeners"
  />
</template>

<script>
/**
 * Generic text input that displays a label and error messages.
 */
export default {
  props: {
    /**
     * Model for the input.
     * @model
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * Model for the input.
     * @model
     */
    value: {
      type: String,
      default: null,
    },
    /**
     * Placeholder text of the input.
     */
    placeholder: {
      type: String,
      default: null,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        /**
         * Triggers model changes.
         *
         * @property {string} newValue new value for model
         */
        this.$emit('input', val);
      },
    },
    inputListeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
  watch: {
    focus: {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.$refs.textInput.focus();
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.text-input {
  font-family: $font-main;
  align-items: center;
  border: 1px solid $border;
  border-radius: $round-corner-small;
  display: flex;
  flex-grow: 1;
  font-size: $x16;
  padding: 0 $space-16;
  margin: 0;
  /* stylelint-disable plugin/no-unsupported-browser-features */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* stylelint-enable */

  &.invalid {
    border-color: $red !important;
  }
}
</style>
