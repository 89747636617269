<template>
  <div class="avatar-container">
    <div class="picture" :class="{ loading: !imageSrc }">
      <SkeletonLoader :loading="!imageSrc" type="circle" :size="32" class="avatar-skeleton">
        <picture class="likeshop-avatar">
          <img :src="imageSrc" :alt="`${handle} logo`" />
        </picture>
      </SkeletonLoader>
    </div>
    <div class="handle" role="heading" aria-level="1">{{ handle }}</div>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/skeleton/SkeletonLoader.vue';

export default {
  components: {
    SkeletonLoader,
  },
  props: {
    handle: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
  },
  computed: {
    showMobileView() {
      return this.$mq.includes('mobile');
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-container {
  display: flex;
  align-items: center;

  .picture {
    border-radius: 50%;
    box-sizing: border-box;
    height: 32px;
    margin-right: $space-8;
    overflow: hidden;
    width: 32px;

    &:not(.loading) {
      border: 1px solid $background-placeholder-dark-grey;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .handle {
    color: $header-text-black;
    font-size: $x14;
    font-weight: $medium;
  }
}
</style>
