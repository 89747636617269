import VueMq from 'vue-mq';

// Repo: https://github.com/AlexandreBonaventure/vue-mq

export default {
  install(Vue) {
    Vue.use(VueMq, {
      breakpoints: {
        'mobile-portrait': 480,
        'mobile-landscape': 768,
        'tablet-portrait': 992,
        'tablet-landscape': 1200,
        desktop: Infinity,
      },
      defaultBreakpoint: 'mobile-portrait', // customize this for SSR
    });
  },
};
