<template>
  <section
    class="email-capture-container"
    :style="{ 'margin-bottom': reactive.mediaGap }"
    role="region"
    aria-label="Email Capture"
  >
    <div v-if="!emailSubmitted" class="email-capture">
      <p id="email-capture-description" class="email-capture-description" aria-hidden="true">
        {{ emailCaptureMessage }}
      </p>
      <div :class="['email-form', { 'desktop-spacing': !$mq.includes('mobile') }]">
        <TextInput
          v-model="emailForm.email"
          :class="{ invalid: showEmailError }"
          data-cy="email-address-input"
          type="email"
          placeholder="Email address"
          aria-labelledby="email-capture-description"
          @input="onInput"
          @keyup.enter.native="subscribe()"
        />
        <Button
          class="submit-email-button"
          aria-label="”Submit”"
          data-cy="subscribe-btn"
          :disabled="disabledSubscribeButton"
          rectangular
          grey
          @click.native="subscribe()"
        >
          {{ buttonLabel }}
        </Button>
      </div>
      <p v-if="showEmailError" class="error-message" role="alert">{{ emailErrorMessage }}</p>
      <p class="policy-notice">
        By submitting your email address you agree to our
        <a
          :href="privacyPolicyLink"
          class="privacy-policy"
          alt="privacy policy link"
          rel="noopener"
          target="_blank"
        >
          Privacy Policy.
        </a>
      </p>
    </div>
    <div v-else class="email-submitted">
      <Icon name="check" xsmall />
      <p class="email-submitted-message" data-cy="email-submitted-msg">
        {{ emailSubmittedMessage }}
      </p>
    </div>
  </section>
</template>

<script>
import { mapStores } from 'pinia';
import merge from 'lodash/merge';
import { likeshopCopy } from '@/config/messages';
import { emailRegex } from '@/utils/validate';
import { useGalleryStore } from '@/stores/gallery';
import { useNotificationStore } from '@/stores/notification';
import Button from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';
import TextInput from '@/components/TextInput.vue';

export default {
  name: 'EmailCapture',
  components: {
    Button,
    Icon,
    TextInput,
  },
  inject: ['reactive'],
  data() {
    return {
      disabledSubscribeButton: false,
      emailErrorMessage: likeshopCopy.invalidEmail,
      emailSubmitted: false,
      emailSubmittedMessage: likeshopCopy.emailSubmitted,
      emailForm: {
        email: '',
      },
      showEmailError: false,
    };
  },
  computed: {
    ...mapStores(useNotificationStore, useGalleryStore),
    emailCaptureSettings() {
      return this.galleryStore.isPreview
        ? merge(
            this.galleryStore.settings?.emailCapture ?? {},
            this.galleryStore.previewOptions?.emailCapture ?? {}
          )
        : this.galleryStore.settings?.emailCapture;
    },
    emailCaptureMessage() {
      return this.emailCaptureSettings?.description || likeshopCopy.defaultEmailCaptureDescription;
    },
    buttonLabel() {
      return this.emailCaptureSettings?.buttonLabel || likeshopCopy.defaultEmailCaptureButtonLabel;
    },
    privacyPolicyLink() {
      return this.emailCaptureSettings?.privacyPolicyLink || 'https://dashhudson.com/privacy';
    },
  },
  methods: {
    onInput() {
      this.showEmailError = false;
    },
    async subscribe() {
      if (this.disabledSubscribeButton) {
        return;
      }

      const { email } = this.emailForm;

      if (!email.match(emailRegex)) {
        this.showEmailError = true;
        return;
      }

      this.disabledSubscribeButton = true;

      try {
        await this.galleryStore.captureEmail({ email });
        this.emailSubmitted = true;
      } catch (e) {
        this.notificationStore.setNotification({
          message: likeshopCopy.captureEmailServerError,
          type: 'error',
        });
        this.$log.error('Failed capturing the email', e);
      }
      this.disabledSubscribeButton = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.email-capture-container {
  max-width: 868px;
  margin: auto;
  background: $background-grey;
  padding: $space-16 $space-24;

  .email-capture {
    max-width: 450px;
    margin: auto;

    .error-message {
      color: $red;
      font-size: $x12;
      margin: $space-8 0 0 0;
    }
  }

  .email-submitted {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 450px;
    margin: auto;
  }

  p {
    color: $grey;

    &.email-capture-description {
      font-weight: $medium;
      font-size: $x14;
      margin-top: 0;
    }

    &.email-submitted-message {
      font-size: $x14;
      font-weight: $semi-bold;
      margin-left: $space-8;
    }

    &.policy-notice {
      font-size: $x12;
      margin-bottom: 0;

      .privacy-policy {
        text-decoration: underline;
        color: $grey;
      }
    }
  }

  .email-form {
    display: flex;

    &.desktop-spacing {
      margin: $space-8 0;
    }

    ::v-deep .text-input:focus-visible {
      outline-color: $link;
    }

    .submit-email-button {
      padding: 11px $space-16;
      margin-left: $space-4;
    }
  }
}
</style>
