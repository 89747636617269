import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import config from '@/config';

export default {
  install(Vue, router) {
    Sentry.init({
      Vue,
      // Catch config issues when setting/troubleshooting locally
      debug: config.isLocal,
      environment: config.env,
      dsn: config.likeshop.sentryDSN,
      release: config.likeshop.sentryRelease,
      integrations: [
        new Integrations.BrowserTracing({
          // We do not use sentry on the backend; localhost added to avoid repeat investigations
          tracingOrigins: ['localhost', /^\//],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],

      sampleRate: config.sentrySampleRate,
      tracesSampleRate: config.sentryTraceSampleRate, // Relates to performance tracking
      trackComponents: true,

      ignoreErrors: ['Error: Request failed with status code 404'],
    });
  },
};
