/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cta-arrow': {
    width: 20,
    height: 10,
    viewBox: '0 0 20 10',
    data: '<path pid="0" d="M13.293 1.707L15.586 4H0v2h15.586l-2.293 2.293 1.413 1.414L19.414 5 14.706.293l-1.413 1.414z" _fill="#757575"/>'
  }
})
