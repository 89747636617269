/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'instagram': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<path pid="0" d="M10.5 0C7.648 0 7.29.012 6.17.063 5.054.114 4.29.292 3.623.551a5.147 5.147 0 00-1.86 1.211 5.147 5.147 0 00-1.21 1.86C.291 4.29.113 5.053.062 6.171.013 7.29 0 7.648 0 10.5c0 2.852.012 3.21.063 4.33.051 1.117.229 1.88.488 2.548.269.69.628 1.276 1.211 1.86.584.583 1.17.942 1.86 1.21.668.26 1.431.438 2.549.489 1.12.05 1.477.063 4.329.063 2.852 0 3.21-.012 4.33-.063 1.117-.051 1.88-.229 2.548-.488a5.147 5.147 0 001.86-1.211 5.147 5.147 0 001.21-1.86c.26-.668.438-1.431.489-2.549.05-1.12.063-1.477.063-4.329 0-2.852-.012-3.21-.063-4.33-.051-1.117-.229-1.88-.488-2.548a5.147 5.147 0 00-1.211-1.86 5.147 5.147 0 00-1.86-1.21c-.668-.26-1.431-.438-2.549-.489C13.71.013 13.352 0 10.5 0zm0 2c2.768 0 3.096.01 4.19.06 1.01.047 1.56.215 1.925.357.484.189.83.413 1.192.776.363.363.587.708.776 1.192.142.366.31.915.357 1.925.05 1.094.06 1.422.06 4.19 0 2.768-.01 3.096-.06 4.19-.047 1.01-.215 1.56-.357 1.925-.189.484-.413.83-.776 1.192a3.212 3.212 0 01-1.192.776c-.366.142-.915.31-1.925.357-1.093.05-1.421.06-4.19.06s-3.096-.01-4.19-.06c-1.01-.047-1.56-.215-1.925-.357a3.212 3.212 0 01-1.192-.776 3.212 3.212 0 01-.776-1.192c-.142-.366-.31-.915-.357-1.925C2.01 13.596 2 13.268 2 10.5c0-2.768.01-3.096.06-4.19.047-1.01.215-1.56.357-1.925.189-.484.413-.83.776-1.192a3.212 3.212 0 011.192-.776c.366-.142.915-.31 1.925-.357C7.404 2.01 7.732 2 10.5 2zm0 3a5.5 5.5 0 100 11 5.5 5.5 0 000-11zm0 9a3.5 3.5 0 110-7 3.5 3.5 0 010 7zM18 5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" _fill="#adadad" fill-rule="nonzero"/>'
  }
})
