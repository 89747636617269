import config from '@/config';
import Config from '@/plugins/config';
import Logger from '@/plugins/logger';
import PortalVuePlugin from '@/plugins/portal-vue';
import SentryPlugin from '@/plugins/sentry';
import VueMQPlugin from '@/plugins/vue-mq';
import FocusVisiblePlugin from '@/plugins/focusVisible';
import VisibleDirective from '@/plugins/visible';
import DatadogPlugin from '@/plugins/datadog';

// Common plugins should be installed here
export default {
  install(Vue, router) {
    Vue.use(Config);
    Vue.use(PortalVuePlugin);
    Vue.use(FocusVisiblePlugin);
    Vue.use(VisibleDirective);
    Vue.use(VueMQPlugin);
    Vue.use(Logger, { enabled: !!config.logLevel, logLevel: config.logLevel });

    if (config.analyticsEnabled) {
      Vue.use(SentryPlugin, router);
    }

    if (config.datadogEnabled) {
      Vue.use(DatadogPlugin);
    }
  },
};
