import { defineStore } from 'pinia';
import config from '@/config';

let maxNotificationId = 0;

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notifications: [],
  }),
  actions: {
    setNotification({
      htmlMessage,
      message,
      subtext,
      type,
      delay = config.notificationDelay,
      actionText,
      actionTo,
      sameLine,
      buttonText,
      buttonAction,
      autoClear = false,
    }) {
      if (htmlMessage && (message || actionText || actionTo)) {
        // eslint-disable-next-line no-console
        console.warn(
          'Notification.vue received props for htmlMessage and one or more of the following: ' +
            'message, actionText, actionTo. They are mutually exclusive.'
        );
      }
      maxNotificationId += 1;
      const id = maxNotificationId;
      this.notifications.push({
        id,
        htmlMessage,
        message,
        subtext,
        type,
        actionText,
        actionTo,
        sameLine,
        buttonText,
        buttonAction,
      });
      if (autoClear) {
        setTimeout(() => this.clearNotification({ id }), delay);
      }
    },
    clearNotification({ id }) {
      this.notifications = this.notifications.filter((n) => n.id !== id);
    },
  },
});
