<template>
  <div />
</template>

<script>
export default {
  name: 'InfiniteScroll',
  props: {
    onInfinite: { type: Function, default: () => {} },
    distance: { type: Number, default: 200 },
    getOnCreated: { type: Boolean, default: false },
    useDocument: { type: Boolean, default: false },
    targetContainer: { type: Object, default: undefined },
    preventRequest: { type: Boolean, default: false },
  },
  data() {
    return {
      scrollableParent: null,
      requestPending: false,
    };
  },
  computed: {
    useLoader() {
      if (this.useDefaultLoader) {
        return true;
      }
      if (this.loaderComponent) {
        return true;
      }
      return false;
    },
    targetElement() {
      return this.targetContainer || this.$el;
    },
  },
  created() {
    if (this.getOnCreated) {
      this.callOnInfinite();
    }
  },
  mounted() {
    this.scrollableParent = this.getScrollableParent(this.targetElement, this.useDocument);
    this.scrollableParent.addEventListener('scroll', this.onScroll, { passive: true });
  },
  destroyed() {
    this.scrollableParent.removeEventListener('scroll', this.onScroll, { passive: true });
  },
  methods: {
    async callOnInfinite() {
      this.requestPending = true;
      await this.onInfinite();
      this.requestPending = false;
    },
    async onScroll() {
      const { callOnInfinite, distance, onInfinite, requestPending, scrollableParent } = this;
      if (requestPending || this.preventRequest) {
        return;
      }
      // If the scroll event is set on the document, we must check the distance from the bottom of the
      // page to trigger the scroll event
      const hasReachedBottom =
        window.innerHeight + window.scrollY + this.distance >= document.body.offsetHeight;
      const pageScroll = this.scrollableParent === document;

      if (pageScroll && hasReachedBottom && onInfinite) {
        await callOnInfinite();
      } else if (
        onInfinite &&
        scrollableParent.scrollTop >=
          scrollableParent.scrollHeight - scrollableParent.offsetHeight - distance
      ) {
        await callOnInfinite();
      }
    },
    // This function will find the next available scrollable parent for the infinite scroll event
    getScrollableParent(element, useDocument) {
      const style = getComputedStyle(element);
      const excludeStaticParent = style.position === 'absolute';
      const overflowRegex = /(auto|scroll)/;

      if (style.position === 'fixed' || useDocument) {
        return document;
      }

      let parent = element.parentElement;
      while (parent) {
        const parentStyle = getComputedStyle(parent);
        if (
          !(excludeStaticParent && parentStyle.position === 'static') &&
          overflowRegex.test(parentStyle.overflow + parentStyle.overflowY + parentStyle.overflowX)
        ) {
          return parent;
        }
        parent = parent.parentElement;
      }
      return document;
    },
  },
};
</script>
