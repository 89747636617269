/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'checkCircle': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<g _fill="#27C449" fill-rule="nonzero"><path pid="0" d="M14.056 27.333C6.704 27.333.723 21.352.723 14S6.704.667 14.056.667 27.389 6.648 27.389 14s-5.981 13.333-13.333 13.333zm0-24C8.175 3.333 3.389 8.12 3.389 14s4.786 10.667 10.667 10.667S24.723 19.88 24.723 14 19.937 3.333 14.056 3.333z"/><path pid="1" d="M12.935 20.57l-5.391-5.39L9.9 12.823l2.61 2.608 5.522-7.732 2.713 1.937z"/></g>'
  }
})
