/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'check': {
    width: 17,
    height: 14,
    viewBox: '0 0 17 14',
    data: '<defs><path pid="0" id="svgicon_check_a" d="M6.168 13.269L0 7.657l1.7-1.701 4.386 3.828L14.859.132l1.78 1.619z"/></defs><use _fill="#7A7A7A" fill-rule="nonzero" xlink:href="#svgicon_check_a"/>'
  }
})
