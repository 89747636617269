<template>
  <picture>
    <template v-if="isValidSourceDomain">
      <source
        v-for="srcset in srcsets"
        :key="`${srcset.extension}`"
        :srcset="srcset.url"
        :type="`image/${srcset.extension}`"
      />
      <img :alt="alt" :src="defaultSrc" :style="imgStyle" v-bind="dynamicBinds" v-on="$listeners" />
    </template>
    <template v-else>
      <img :alt="alt" :src="url" :style="imgStyle" v-bind="dynamicBinds" v-on="$listeners" />
    </template>
  </picture>
</template>

<script>
import {
  IMAGE_API_URLS,
  getImageApiHostForUrl,
  sourceDomainValidator,
  urlEncode,
  imageApiFitValidation,
  imageApiSizeValidation,
} from '@/utils/imageApi';

export default {
  props: {
    alt: { type: String, default: null },
    url: {
      type: String,
      required: true,
    },
    fit: imageApiFitValidation,
    imgStyle: { type: Object, default: () => {} },
    size: imageApiSizeValidation,
  },
  computed: {
    isValidSourceDomain() {
      return sourceDomainValidator(this.url);
    },
    cleanedURL() {
      const isFromImageAPI = IMAGE_API_URLS.some((imageAPIUrl) => this.url.includes(imageAPIUrl));
      if (isFromImageAPI) {
        const url = new URL(this.url);
        const pathArguments = url.pathname.split('/');
        const base64Encoding = pathArguments[1].split('.')[0]; // Toss the extension if provided
        return atob(base64Encoding);
      }
      return this.url;
    },
    defaultSrc() {
      if (this.srcsets) {
        // This is only used as a fallback for older browsers; unlikely to get used.
        return this.srcsets[1]?.url;
      }
      return null;
    },
    encodedUrl() {
      return urlEncode(this.cleanedURL);
    },
    host() {
      return getImageApiHostForUrl(this.cleanedURL);
    },
    is2X() {
      return window.devicePixelRatio > 1;
    },
    adjustedSize() {
      if (!this.is2X) {
        return this.size;
      }

      if (this.size.w === this.size.h) {
        const width2X = this.size.w * 2;
        return Object.values(this.$config.imageSizes).reduce((prev, curr) => {
          return Math.abs(curr.w - width2X) < Math.abs(prev.w - width2X) ? curr : prev;
        });
      }

      const size2X = { w: this.size.w * 2, h: this.size.h * 2 };
      const sizeRatio = (this.size.h / this.size.w).toFixed(3);
      // we want to find an accepted image size that is larger, and has the same ratio
      const sameRatioImageSizes = Object.values(this.$config.imageSizes).filter((size) => {
        return (size.h / size.w).toFixed(3) === sizeRatio;
      });

      if (sameRatioImageSizes.length === 0) {
        return this.size;
      }

      // find an accepted size that is the largest
      return sameRatioImageSizes.reduce((prev, curr) => {
        // we want an accepted image size that is closest to the 2x image width and height
        // we find the the closest size by minimizing the distance squared
        return (curr.w - size2X.w) ** 2 + (curr.h - size2X.h) ** 2 <
          (prev.w - size2X.w) ** 2 + (prev.h - size2X.h)
          ? curr
          : prev;
      });
    },
    srcsets() {
      return ['webp', 'jpeg'].map((extension) => this.getSrcset(extension));
    },
    dynamicBinds() {
      if (this.fit === 'cover') {
        return {
          width: this.adjustedSize.w,
          height: this.adjustedSize.h,
        };
      }
      // We won't know the dimensions before the request with inside fit.
      return {};
    },
  },
  methods: {
    getSrcset(extension) {
      if (!this.cleanedURL) {
        return null;
      }
      const width = this.adjustedSize.w;
      const height = this.adjustedSize.h;
      const fitParam = this.fit ? `&fit=${this.fit}` : '';
      const url = `${this.host}/${this.encodedUrl}.${extension}?h=${height}&w=${width}${fitParam}`;
      return {
        extension,
        url,
        height,
        width,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
picture {
  img {
    width: 100%;
    display: block;
    height: auto;
  }
}
</style>
