/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'close': {
    width: 23,
    height: 23,
    viewBox: '0 0 23 23',
    data: '<path pid="0" d="M2.257.774l9.281 9.281L20.804.79l1.642 1.642-9.266 9.265 9.361 9.361-1.674 1.674-9.36-9.361-9.346 9.345L.52 21.074l9.346-9.345L.583 2.448z" _fill="#adadad" fill-rule="evenodd"/>'
  }
})
