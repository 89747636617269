<template>
  <div>
    <GridSkeletonLoader
      id="main-content"
      :count="51"
      :loading="galleryStore.pending.likeshopGallery"
      aria-label="List of LikeShop Product Links"
      role="feed"
      columns="3"
      :gap="reactive.mediaGap"
      :style="`margin-bottom: ${reactive.mediaGap}`"
      max-width="916px"
      :item-options="{
        type: 'picture',
        itemHeight: itemHeight,
      }"
    >
      <LikeShopItem
        v-for="(media, index) in galleryStore.galleryMedia"
        :key="index"
        :aria-posinset="index + 1"
        aria-setsize="-1"
        :call-to-action="galleryStore.callToAction"
        :media="media"
        :media-size="mediaSize"
        object-fit="cover"
      />
    </GridSkeletonLoader>
    <InfiniteScroll
      :on-infinite="galleryStore.loadMoreGalleryMedia"
      :prevent-request="shouldPreventOnInfinite"
      :distance="380"
      use-document
    />
    <CircularLoader v-if="galleryStore.pending.galleryMedia" />
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useGalleryStore } from '@/stores/gallery';
import CircularLoader from '@/components/CircularLoader.vue';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import LikeShopItem from '@likeshop/components/LikeShopItem.vue';
import GridSkeletonLoader from '@/components/skeleton/GridSkeletonLoader.vue';
import { mediaFormat } from '@/likeshop/constants';

export default {
  components: {
    CircularLoader,
    GridSkeletonLoader,
    InfiniteScroll,
    LikeShopItem,
  },
  inject: ['reactive'],
  props: {
    mediaFormat: {
      type: String,
      default: mediaFormat.SQUARE,
      validator: (value) => value in mediaFormat,
    },
  },
  data() {
    return {
      mediaSizeMap: {
        [mediaFormat.SQUARE]: {
          mobile: this.$config.imageSizes.w150h150,
          desktop: this.$config.imageSizes.w300h300,
        },
        [mediaFormat.PORTRAIT]: {
          mobile: this.$config.imageSizes.w150h200,
          desktop: this.$config.imageSizes.w300h400,
        },
      },
    };
  },
  computed: {
    ...mapStores(useGalleryStore),
    mediaSize() {
      if (this.$mq === 'mobile-portrait' || this.$mq === 'mobile-landscape') {
        return this.mediaSizeMap?.[this.mediaFormat]?.mobile;
      }
      return this.mediaSizeMap?.[this.mediaFormat]?.desktop;
    },
    itemHeight() {
      const heightRatio = (this.mediaSize.h / this.mediaSize.w) * 100;
      return `${heightRatio.toFixed(2)}%`;
    },
    shouldPreventOnInfinite() {
      return !this.galleryStore.nextUrl || this.galleryStore.error.galleryMedia;
    },
  },
};
</script>

<style lang="scss" scoped>
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.grid-layout-container {
  margin: 0 auto;
}
</style>
