/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'video': {
    width: 31,
    height: 32,
    viewBox: '0 0 31 32',
    data: '<defs><filter x="-124%" y="-96.7%" width="348%" height="306.7%" filterUnits="objectBoundingBox" id="svgicon_video_a"><feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter2"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter2" result="shadowBlurOuter2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.23 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="shadowMatrixOuter2"/></feMerge></filter><path pid="0" id="svgicon_video_b" d="M101.167 135.502v15l12.5-7.5z"/></defs><g transform="translate(-91 -128)" _fill="none" fill-rule="evenodd"><use _fill="#000" filter="url(#svgicon_video_a)" xlink:href="#svgicon_video_b"/><use _fill="#FFF" xlink:href="#svgicon_video_b"/></g>'
  }
})
