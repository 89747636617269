<template>
  <GridLayout
    :id="id"
    :aria-busy="ariaBusy"
    :aria-label="ariaLabel"
    :role="role"
    :columns="columns"
    :gap="gap"
    :min-item-width="minItemWidth"
    :max-item-width="maxItemWidth"
    :item-height="itemHeight"
    :max-width="maxWidth"
  >
    <template v-if="loading">
      <SkeletonLoader v-for="n in count" :key="n" :loading="true" v-bind="itemOptions" />
    </template>
    <slot v-else></slot>
  </GridLayout>
</template>

<script>
import GridLayout from '@/components/GridLayout.vue';
import SkeletonLoader, { SKELETON_TYPES } from '@/components/skeleton/SkeletonLoader.vue';
import { logger } from '@/plugins/logger';

const validItemTypes = [SKELETON_TYPES.PictureSkeleton.type];

export default {
  name: 'GridSkeletonLoader',
  components: {
    GridLayout,
    SkeletonLoader,
  },
  props: {
    id: { type: String, default: null },
    ariaBusy: { type: String, default: 'false' },
    ariaLabel: { type: String, default: '' },
    role: { type: String, default: 'grid' },
    columns: { type: [String, Number], default: 3 },
    count: { type: Number, default: 1 },
    gap: { type: String, default: '1.5rem' },
    groupBy: { type: Number, default: 0 },
    loading: { type: Boolean, default: true },
    minItemWidth: { type: String, default: '15rem' },
    maxItemWidth: { type: String, default: '15rem' },
    itemWidth: { type: String, default: '15rem' },
    itemHeight: { type: String, default: 'auto' },
    maxWidth: { type: String, default: null },
    itemOptions: {
      type: Object,
      required: true,
      validator(value) {
        const validType = validItemTypes.indexOf(value.type) >= 0;
        if (!validType) {
          logger.warn(
            `Only skeletons of these types are accepted: ${Object.keys(validItemTypes).join(' ')}`
          );
        }
        return validType;
      },
    },
  },
};
</script>
