import config from '@/config';
import { logger } from '@/plugins/logger';

const prodSourceDomains = [config.prodS3Url, config.prodCdnUrl, config.prodCloudFrontUrl];
const oldDevSourceDomains = [config.oldDevS3Url, config.oldDevCdnUrl, config.oldDevCloudFrontUrl];
const devSourceDomains = [config.devS3Url, config.devCdnUrl, config.devCloudFrontUrl];

export const IMAGE_API_URLS = [
  config.oldDevImageApiUrl,
  config.devImageApiUrl,
  config.prodImageApiUrl,
];

export const ALLOWED_SOURCE_DOMAINS = [
  ...prodSourceDomains,
  ...devSourceDomains,
  ...oldDevSourceDomains,
  ...IMAGE_API_URLS,
];
export const sourceDomainValidator = (value) => {
  return ALLOWED_SOURCE_DOMAINS.some((allowedSourceDomain) => value.includes(allowedSourceDomain));
};

export const getImageApiHostForUrl = (urlOrig) => {
  let host = config.devImageApiUrl;
  prodSourceDomains.forEach((cdnUrl) => {
    if (urlOrig.startsWith(cdnUrl)) {
      host = config.prodImageApiUrl;
    }
  });
  oldDevSourceDomains.forEach((cdnUrl) => {
    if (urlOrig.startsWith(cdnUrl)) {
      host = config.oldDevImageApiUrl;
    }
  });
  return host;
};

export const urlEncode = (unencoded) => {
  const encoded = Buffer.from(unencoded || '').toString('base64');
  return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

export const ALLOWED_IMAGE_SIZES = config.imageSizes;

export const allowedImageSizesValidator = (value) => {
  return Object.values(ALLOWED_IMAGE_SIZES).some((size) => {
    return value.w === size.w && value.h === size.h;
  });
};

export const FIT_INSIDE = 'inside';
export const FIT_COVER = 'cover';
export const ALLOWED_FITS = [FIT_INSIDE, FIT_COVER, null];

const fitValidator = (value) => {
  return ALLOWED_FITS.some((fit) => value.includes(fit));
};

export const imageApiFitValidation = {
  type: String,
  default: null,
  validator: fitValidator,
};

export const imageApiSizeValidation = {
  type: Object,
  required: true,
  validator(value) {
    const isValidSize = allowedImageSizesValidator(value);
    if (!isValidSize) {
      logger.warn(
        `Picture.vue only allows sizes of: ${Object.keys(ALLOWED_IMAGE_SIZES).join(' ')}`
      );
    }
    return isValidSize;
  },
};
