<template>
  <div
    class="banner-container"
    :style="{ 'margin-bottom': reactive.mediaGap }"
    role="region"
    aria-label="Likeshop Banner"
  >
    <div class="banner-frame">
      <LikeShopItem :media="banner" :media-size="mediaSize" :default-alt-text="'Banner Image'" />
    </div>
  </div>
</template>

<script>
import LikeShopItem from '@likeshop/components/LikeShopItem.vue';

export default {
  name: 'LikeshopBanner',
  components: {
    LikeShopItem,
  },
  inject: ['reactive'],
  props: {
    banner: { type: Object, required: true },
  },
  computed: {
    mediaSize() {
      if (this.$mq === 'mobile-portrait' || this.$mq === 'mobile-landscape') {
        return this.$config.imageSizes.w640h640;
      }
      return this.$config.imageSizes.w1280h1280;
    },
  },
};
</script>

<style scoped lang="scss">
.banner-container {
  position: relative;
  width: 100%;
  padding-top: 50%;

  .banner-frame {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    ::v-deep .media-container {
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
