import Vue from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import config from '@/config';
import DHCommonPlugins from '@/plugins';
import GTag from '@/plugins/gtag';
import App from '@likeshop/App.vue';
import router from '@likeshop/router';

Vue.config.productionTip = false;

Vue.use(DHCommonPlugins, router);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

if (config.analyticsEnabled) {
  Vue.use(GTag, {
    config: config.gtm,
  });
}

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app');
