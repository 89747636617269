export const likeshopCopy = {
  serverError: 'Unable to load LikeShop, please try again.',
  notFound: "Whoops, that page doesn't exist.",
  hasNoMedia: 'Add Links to Media to Populate Your LikeShop',
  noCaption: 'No caption provided',
  defaultMobileCta: 'Tap media to learn more',
  defaultDesktopCta: 'Click on media to learn more',
  defaultMobileProductTitle: 'Tap to learn more',
  defaultDesktopProductTitle: 'Click to learn more',
  defaultEmailCaptureDescription:
    'Subscribe to our newsletter and be the first to hear about our upcoming launches.',
  defaultEmailCaptureButtonLabel: 'Subscribe',
  emailSubmitted: 'Your email has been submitted.',
  invalidEmail: 'Please enter a valid email address.',
  captureEmailServerError: 'Unable to send email address. Please try again later.',
  cookiePolicyMessage: 'Our website uses cookies to ensure you get the best experience.',
  cookiePolicyUrlLabel: 'Learn more',
  noProductsReturnedTitle: 'All links have been removed.',
  noProductsReturnedActionText: 'Please select another post.',
};
