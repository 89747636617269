import { datadogRum } from '@datadog/browser-rum';
import config from '@/config';

export default {
  install() {
    datadogRum.init({
      applicationId: config.datadogApplicationId,
      clientToken: config.datadogClientToken,
      service: 'shopability',
      env: config.env,
      allowedTracingOrigins: [
        /https:\/\/(?!cdn|images).*\.dhdev\.io/,
        /https:\/\/(?!cdn|images).*\.dashhudson\.com/,
      ],
      sampleRate: config.datadogRumSampleRate,
      sessionReplaySampleRate: 0,
      tracingSampleRate: config.datadogRumTraceSampleRate,
    });
  },
};
