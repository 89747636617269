<template>
  <div
    id="app"
    v-focus-visible
    :class="{ 'is-preview': galleryStore.isPreview }"
    @click.capture="globalClickHandler"
    @focus.capture="globalFocusHandler"
  >
    <!-- We don't need router links here as there is no nav but we
    do need router-view to enable in-component route guards -->
    <router-view />
    <portal-target
      role="dialog"
      aria-label="The popup is open."
      aria-modal="true"
      name="popups"
      multiple
    />
  </div>
</template>

<script>
import '@/styles/likeshop.scss';
import { mapStores } from 'pinia';
import { useGalleryStore } from '@/stores/gallery';

export default {
  provide() {
    return {
      reactive: this.reactive,
    };
  },
  data() {
    return {
      reactive: {
        mediaGap: null,
      },
    };
  },
  computed: {
    ...mapStores(useGalleryStore),
  },
  watch: {
    $mq() {
      this.setMediaGap();
    },
  },
  created() {
    if (!this.reactive.mediaGap) {
      this.setMediaGap();
    }
    if (!['NotFound', 'WidgetsGuide'].includes(this.$route.name)) {
      this.galleryStore.setRouteData({ $route: this.$route });
    }
  },
  methods: {
    setMediaGap() {
      this.reactive.mediaGap = this.$mq?.includes('mobile') ? '2px' : '8px';
    },
    // We don't want to allow for the preview to be used nefariously
    globalClickHandler(event) {
      if (this.galleryStore.isPreview) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    globalFocusHandler(event) {
      if (this.galleryStore.isPreview) {
        event.target.blur();
        event.preventDefault();
        event.stopPropagation();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.is-preview {
  pointer-events: none;
}
</style>
