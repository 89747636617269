<template>
  <section class="error-message" data-cy="error-message">
    <p>{{ message }}</p>
    <slot />
  </section>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    message: { type: String, default: '' },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  padding: $space-88 $space-48;

  p {
    color: $grey;
    font-size: $x18;
    text-align: center;
    margin-bottom: 0;
  }
}
</style>
