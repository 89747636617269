import Vue from 'vue';
import VueRouter from 'vue-router';
import { LikeShopGalleryType } from '@/apis/likeshop';
import { likeshopCopy } from '@/config/messages';
import ErrorMessage from '@/likeshop/components/ErrorMessage.vue';
import Home from '@/likeshop/pages/instagram/Home.vue';
import TiktokHome from '@/likeshop/pages/tiktok/Home.vue';
import WidgetsGuide from '@likeshop/pages/docs/WidgetsGuide.vue';

Vue.use(VueRouter);

const routes = [
  {
    name: 'WidgetsGuide',
    path: '/app/docs/widgets-guide',
    component: WidgetsGuide,
  },
  {
    path: '/:instagramHandle',
    name: 'Home',
    component: Home,
    meta: {
      title: 'LikeShop',
      galleryType: LikeShopGalleryType.INSTAGRAM,
    },
    children: [
      {
        path: 'media/:galleryMediaId',
        name: 'LikeShopMediaPopup',
        meta: {
          title: 'LikeShop',
          galleryType: LikeShopGalleryType.INSTAGRAM,
        },
      },
    ],
  },
  {
    path: '/preview/:instagramHandle',
    name: 'Preview',
    component: Home,
    meta: {
      isPreview: true,
      galleryType: LikeShopGalleryType.INSTAGRAM,
    },
  },
  {
    path: '/t/:tiktokHandle',
    name: 'TiktokHome',
    component: TiktokHome,
    meta: {
      title: 'TikTok LikeShop',
      galleryType: LikeShopGalleryType.TIKTOK,
    },
    children: [
      {
        path: 'media/:galleryMediaId',
        name: 'TiktokLikeShopMediaPopup',
        meta: {
          title: 'TikTok LikeShop',
          galleryType: LikeShopGalleryType.TIKTOK,
        },
      },
    ],
  },
  {
    path: '/t/preview/:tiktokHandle',
    name: 'TikTokLikeShopPreview',
    component: TiktokHome,
    meta: {
      isPreview: true,
      galleryType: LikeShopGalleryType.TIKTOK,
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: ErrorMessage,
    props: {
      message: likeshopCopy.notFound,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
