<template>
  <button :class="['button', classes, { 'disabled-btn': disabled }]" :disabled="disabled">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    grey: { type: Boolean, default: false },
    rectangular: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      return {
        disabled: this.$props.disabled || this.$props.loading,
        grey: this.$props.grey,
        rectangular: this.$props.rectangular,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 0 $space-24;
  margin: 0;
  border: none;
  font-family: inherit;
  font-size: $x14;
  font-weight: $medium;
  color: $grey;
  background-color: $background-grey;
  cursor: pointer;
  transition: $transition-all;
}

.disabled-btn {
  &:hover {
    color: $grey;

    &.grey {
      opacity: 0.7;
      color: $white;
    }
  }
}

.disabled {
  opacity: 0.3;
  cursor: auto;

  &.focus,
  &:hover,
  &:active {
    pointer-events: none !important;
    transform: none !important;
    box-shadow: none;
  }
}

.grey.disabled,
.light.disabled {
  opacity: 1;
}

.rectangular {
  border-radius: $round-corner-small;
}

.grey {
  background: $grey;
  color: $white;
}
</style>
