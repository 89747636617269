/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'igtv': {
    width: 31,
    height: 32,
    viewBox: '0 0 31 32',
    data: '<defs><filter x="-116.7%" y="-68.2%" width="333.3%" height="290.9%" filterUnits="objectBoundingBox" id="svgicon_igtv_a"><feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter2"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter2" result="shadowBlurOuter2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.23 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="shadowMatrixOuter2"/></feMerge></filter><path pid="0" d="M11.42.175l.079.07c.3.3.323.772.07 1.099l-.07.079-2.745 2.743H10a5 5 0 014.995 4.783l.005.217v4.167a5 5 0 01-4.783 4.995l-.217.005H5A5 5 0 01.005 13.55L0 13.333V9.166a5 5 0 014.783-4.995L5 4.166h1.398L3.654 1.423a.833.833 0 011.1-1.248l.078.07 2.744 2.742L10.32.244c.3-.3.773-.323 1.1-.07zM7.324 9.249l-.087.034-3.467 1.661-.073.041c-.275.179-.416.558-.346.922l.032.12.034.088c.15.333.472.503.777.412l.091-.036 2.516-1.205v1.27l.004.097c.045.47.412.801.8.726l.09-.025 3.476-1.25.077-.033c.287-.148.455-.511.412-.88l-.023-.124-.027-.091c-.125-.348-.433-.551-.744-.493l-.093.025-2.584.928v-1.38l-.004-.096c-.047-.5-.456-.836-.861-.71z" id="svgicon_igtv_b"/></defs><g transform="translate(10.5 5.776)" _fill="none" fill-rule="evenodd"><use _fill="#000" filter="url(#svgicon_igtv_a)" xlink:href="#svgicon_igtv_b"/><use _fill="#FFF" xlink:href="#svgicon_igtv_b"/></g>'
  }
})
