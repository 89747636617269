<template>
  <div class="home" :aria-busy="ariaBusy">
    <CookieBanner
      v-if="showCookieBanner"
      :platform-type="platformType"
      :social-media-account-name="instagramHandle"
      @allowCookies="updateCookies"
    />
    <SkipToMainContent v-if="showSkipToContent" />
    <Header
      :platform-type="platformType"
      :avatar-src="galleryStore.avatarUrl"
      :handle="instagramHandle"
    />
    <ErrorMessage v-if="errorMessage" :message="errorMessage" />
    <template v-else>
      <main role="main">
        <CallToAction v-if="!hideCTA" :message="galleryStore.callToAction" />
        <div class="container">
          <LikeshopBanner v-if="banner" :banner="banner" />

          <LikeShopPinnedContent
            v-if="showPinnedContent"
            :gallery="galleryStore.likeshopGallery"
            :media-format="mediaFormat"
          />

          <EmailCapture v-if="showEmailCapture" />

          <MediaList :media-format="mediaFormat" />
        </div>
        <Notification v-if="notificationStore.notifications.length > 0" />
      </main>
      <FacebookPixel :pixel-id="facebookPixelId" />
    </template>

    <LikeShopMediaPopup v-if="displayLikeShopPopup" :back-route="backRoute" />
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { mediaFormat, platformType } from '@/likeshop/constants';
import { likeshopCopy } from '@/config/messages';
import { browserStorageGetItem, getCookieConsentKey } from '@/utils/browserStorage';
import { isMobileClient } from '@/utils/mobile';
import { sendOK } from '@/utils/message';
import { useGalleryStore } from '@/stores/gallery';
import { useNotificationStore } from '@/stores/notification';
import FacebookPixel from '@/components/FacebookPixel.vue';
import CallToAction from '@/likeshop/components/CallToAction.vue';
import CookieBanner from '@/likeshop/components/CookieBanner.vue';
import EmailCapture from '@/likeshop/components/EmailCapture.vue';
import ErrorMessage from '@likeshop/components/ErrorMessage.vue';
import Header from '@likeshop/components/Header.vue';
import LikeshopBanner from '@likeshop/components/LikeshopBanner.vue';
import MediaList from '@likeshop/components/MediaList.vue';
import LikeShopPinnedContent from '@/likeshop/components/LikeShopPinnedContent.vue';
import SkipToMainContent from '@/components/SkipToMainContent.vue';

export default {
  components: {
    CallToAction,
    CookieBanner,
    EmailCapture,
    ErrorMessage,
    FacebookPixel,
    Header,
    MediaList,
    LikeshopBanner,
    LikeShopPinnedContent,
    SkipToMainContent,
    LikeShopMediaPopup: () => import('@likeshop/components/LikeShopMediaPopup.vue'),
    Notification: () => import('@/components/Notification.vue'),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.name === 'LikeShopMediaPopup' && !from.name) {
        vm.backRoute = 'Home';
      }
    });
  },
  data: () => {
    return {
      backRoute: null,
      facebookPixelId: null,
      showCookieBanner: false,
      lastActiveElement: null,
      mediaFormat: mediaFormat.SQUARE,
      platformType: platformType.INSTAGRAM,
    };
  },
  computed: {
    ...mapStores(useNotificationStore, useGalleryStore),
    ariaBusy() {
      return this.galleryStore.pending.likeshopGallery ? 'true' : 'false';
    },
    errorMessage() {
      if (this.galleryStore.serverError) return likeshopCopy.serverError;
      if (this.galleryStore.notFound) return likeshopCopy.notFound;
      if (this.galleryStore.hasNoMedia) return likeshopCopy.hasNoMedia;
      return null;
    },
    instagramHandle() {
      return this.$route?.params?.instagramHandle;
    },
    showEmailCapture() {
      if (this.galleryStore.pending.likeshopGallery) {
        return false;
      }

      const enabled = this.galleryStore.settings?.emailCapture?.enabled;

      if (this.galleryStore.isPreview && this.galleryStore.previewOptions) {
        const previewEnabled = this.galleryStore.previewOptions?.emailCapture?.enabled;
        return previewEnabled ?? enabled;
      }

      return enabled;
    },
    showPinnedContent() {
      if (this.galleryStore.pending.likeshopGallery) {
        return false;
      }
      return this.galleryStore.likeshopGallery?.pinned?.some((pin) => pin);
    },
    showSkipToContent() {
      return !this.galleryStore.pending.galleryMedia && !this.errorMessage;
    },
    hideCTA() {
      return this.galleryStore.settings?.hideCallToAction ?? false;
    },
    displayLikeShopPopup() {
      return this.$route.name === 'LikeShopMediaPopup';
    },
    banner() {
      return this.galleryStore?.likeshopGallery?.banner;
    },
  },
  watch: {
    displayLikeShopPopup(to) {
      if (to) {
        this.lastActiveElement = document.activeElement;
        return;
      }
      if (this.lastActiveElement !== null) {
        this.lastActiveElement.focus();
      }
    },
  },
  beforeCreate() {
    document.title = `@${this.$route?.params?.instagramHandle} ${this.$route.meta.title}`;
  },
  async created() {
    await this.loadLikeShopGallery();

    if (this.galleryStore.isPreview) {
      if (this.galleryStore.likeshopGallery) {
        sendOK();
      }
      return;
    }
    const consentKey = getCookieConsentKey(this.platformType, this.instagramHandle);
    const allowTrackingConsent = browserStorageGetItem(consentKey);
    const hasFacebookPixel = Boolean(this.galleryStore.settings?.facebookPixelId);

    this.showCookieBanner = allowTrackingConsent === null && hasFacebookPixel;

    if (allowTrackingConsent === 'true') {
      this.setFacebookPixel();
    }
    this.galleryStore.trackPageView();
  },
  methods: {
    setFacebookPixel() {
      this.facebookPixelId = this.galleryStore.settings?.facebookPixelId || 0;
    },
    async loadLikeShopGallery() {
      const limit = isMobileClient() ? this.$config.mobilePageLimit : this.$config.desktopPageLimit;
      await this.galleryStore.getLikeShopGallery({ limit });
    },
    updateCookies(val) {
      this.showCookieBanner = false;
      if (val) {
        this.setFacebookPixel();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    max-width: 916px;
    width: 100%;
    margin: auto;
  }
}
</style>
