import * as Sentry from '@sentry/vue';

export const LOG_LEVELS = {
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
};

export const DEFAULTS = {
  enabled: true,
  logLevel: LOG_LEVELS.DEBUG,
  separator: '|',
};

function initInstance(options, levels) {
  const logger = {
    client: Sentry,
  };
  levels.forEach((logLevel) => {
    if (options.enabled && levels.indexOf(logLevel) >= levels.indexOf(options.logLevel)) {
      logger[logLevel] = (...args) => {
        const prefix = `${logLevel} ${options.separator} `;

        // eslint-disable-next-line no-console
        console[logLevel](prefix, ...args);

        // If all errors are due to a bad connection we can't do anything
        const errorArgs = args.filter((arg) => arg instanceof Error);
        if (
          errorArgs.length > 0 &&
          errorArgs.every((arg) => arg.message === 'Network Error' && !arg.response)
        ) {
          return null;
        }

        const message = `${prefix}${args.toString()}`;

        if (Sentry) {
          Sentry.captureMessage(message, logLevel);
          [...args].forEach((arg) => {
            // We can't do anything about errors due to bad connections
            if (arg.message === 'Network Error' && !arg.response) {
              return null;
            }
            if (arg && arg.stack && arg.message) {
              Sentry.captureException(arg);
            }
            return null;
          });
        }

        return message;
      };
    } else {
      logger[logLevel] = () => undefined;
    }
  });
  return logger;
}

function install(Vue, options) {
  install.instance = initInstance({ ...DEFAULTS, ...options }, Object.values(LOG_LEVELS));
  Object.defineProperty(Vue, '$log', { value: install.instance });
  Object.defineProperty(Vue.prototype, '$log', { value: install.instance });
}

export const logger = Object.values(LOG_LEVELS).reduce((acc, level) => {
  acc[level] = (...args) => install.instance[level](...args);
  return acc;
}, {});

export default {
  install,
};
