<template>
  <section data-cy="call-to-action">
    <p class="call-to-action">{{ message }}</p>
  </section>
</template>

<script>
export default {
  name: 'CTA',
  props: {
    message: { type: String, default: '' },
  },
};
</script>

<style lang="scss" scoped>
.call-to-action {
  padding: $space-8;
  color: $grey;
  font-size: $x14;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  min-height: 18px;
  text-align: center;
}
</style>
