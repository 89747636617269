import { defineStore } from 'pinia';
import LikeShopApi, { LikeShopGalleryType } from '@/apis/likeshop';
import { isMobileClient } from '@/utils/mobile';
import { logger } from '@/plugins/logger';
import { likeshopCopy } from '@/config/messages';

export const GalleryErrorType = Object.freeze({
  SERVER_ERROR: 'SERVER_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  HAS_NO_MEDIA: 'HAS_NO_MEDIA',
  INVALID_MEDIA: 'INVALID_MEDIA',
});

// Used to prevent double clickers from causing abort errors and inflated counts
const inProgressTrackingRequests = {};

const getCallToAction = (gallery) => {
  if (gallery?.settings?.callToAction) {
    return gallery.settings.callToAction;
  }
  return isMobileClient() ? likeshopCopy.defaultMobileCta : likeshopCopy.defaultDesktopCta;
};

export const useGalleryStore = defineStore('gallery', {
  state: () => ({
    galleryType: null,
    accountName: null,
    isPreview: false,
    previewOptions: null,

    likeshopGallery: null,
    galleryMedia: null,
    galleryMediaDetail: null,
    nextUrl: null,
    callToAction: null,

    serverError: false,
    notFound: false,
    hasNoMedia: false,
    invalidMedia: false,

    pending: {
      likeshopGallery: false,
      galleryMedia: false,
    },
    error: {
      galleryMedia: false,
    },
  }),
  getters: {
    avatarUrl: (state) => {
      if (state.galleryType === LikeShopGalleryType.INSTAGRAM) {
        return state.likeshopGallery?.instagramAccount?.avatarUrl;
      }
      if (state.galleryType === LikeShopGalleryType.TIKTOK) {
        return state.likeshopGallery?.tiktokAccount?.avatarUrl;
      }
      return null;
    },
    settings: (state) => state.likeshopGallery?.settings,
  },
  actions: {
    setRouteData({ $route }) {
      this.galleryType = $route?.meta?.galleryType;

      if (this.galleryType === LikeShopGalleryType.INSTAGRAM) {
        this.accountName = $route?.params?.instagramHandle;
      } else if (this.galleryType === LikeShopGalleryType.TIKTOK) {
        this.accountName = $route?.params?.tiktokHandle;
      } else if (this.galleryType) {
        throw new Error(`Invalid gallery type '${this.galleryType}' for route: ${$route?.path}`);
      } else {
        throw new Error(`Missing gallery type for route: ${$route?.path}`);
      }

      if (!$route?.meta?.isPreview) {
        this.isPreview = false;
        this.previewOptions = null;
        return;
      }
      this.isPreview = true;
      if (!$route?.query?.preview_options) {
        this.previewOptions = null;
        return;
      }
      try {
        this.previewOptions = JSON.parse($route.query.preview_options);
      } catch (error) {
        logger.error('Failed to parse preview options', error);
        this.previewOptions = null;
      }
    },
    async getLikeShopGallery({ limit }) {
      this.pending.likeshopGallery = true;
      try {
        const response = await LikeShopApi.getLikeShopGallery({
          galleryType: this.galleryType,
          accountName: this.accountName,
          limit,
          previewOptions: this.previewOptions,
        });
        const { data, paging } = response.data;
        this.likeshopGallery = data;
        this.callToAction = getCallToAction(this.likeshopGallery);
        this.galleryMedia = this.likeshopGallery?.media;
        this.nextUrl = paging?.next;
        this.hasNoMedia = (this.galleryMedia?.length ?? 0) === 0;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.notFound = true;
        } else if (error?.response?.status >= 500) {
          this.serverError = true;
        }
        logger.error('Failed getting LikeShop gallery', error);
      } finally {
        this.pending.likeshopGallery = false;
      }
    },
    async loadMoreGalleryMedia() {
      this.pending.galleryMedia = true;
      try {
        const response = await LikeShopApi.loadMoreGalleryMedia({ nextUrl: this.nextUrl });
        const { data, paging } = response.data;
        this.galleryMedia = this.galleryMedia.concat(data);
        this.nextUrl = paging?.next;
      } catch (error) {
        logger.error('Failed fetching more gallery media', error);
        this.error.galleryMedia = true;
      } finally {
        this.pending.galleryMedia = false;
      }
    },
    async getGalleryMediaDetail({ galleryMediaId }) {
      try {
        const response = await LikeShopApi.getGalleryMediaDetail({
          galleryType: this.galleryType,
          accountName: this.accountName,
          galleryMediaId,
        });
        this.galleryMediaDetail = response.data?.data;
      } catch (error) {
        if (error?.response?.status === 404 || error?.response?.status >= 500) {
          this.invalidMedia = true;
        }
        logger.error('Failed getting gallery media detail', error);
      }
    },
    async trackPageView() {
      if (this.likeshopGallery) {
        try {
          await LikeShopApi.trackPageView({
            galleryType: this.galleryType,
            accountName: this.accountName,
            galleryId: this.likeshopGallery?.id,
          });
        } catch (error) {
          logger.error('Failed tracking page view', error);
        }
      }
    },
    async trackProductLinkClick({ event, galleryMediaId, product }) {
      event.preventDefault();
      if (inProgressTrackingRequests[product.id]) return;
      try {
        inProgressTrackingRequests[product.id] = true;
        await LikeShopApi.trackProductLinkClick({
          galleryType: this.galleryType,
          accountName: this.accountName,
          galleryId: this.likeshopGallery?.id,
          galleryMediaId,
          product,
        });
      } catch (error) {
        logger.error('Failed tracking product link', error);
      } finally {
        delete inProgressTrackingRequests[product.id];
        const { href } = event.target.closest('a');
        if (isMobileClient()) {
          window.location.href = href;
        } else {
          window.open(href);
        }
      }
    },
    async captureEmail({ email }) {
      await LikeShopApi.captureEmail({
        galleryType: this.galleryType,
        accountName: this.accountName,
        email,
      });
    },
    clearGalleryMediaDetail() {
      this.galleryMediaDetail = null;
      this.invalidMedia = false;
    },
  },
});
