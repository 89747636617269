<template>
  <article
    :class="['media-container', { clickable: hasLinks }]"
    data-cy="media-container"
    :data-media-id="media.id"
  >
    <div class="media-badges">
      <Icon v-if="videoIconName" :color="$config.colors.white" :name="videoIconName" xxxlarge />
    </div>
    <router-link v-if="media.hasMultipleProducts" :to="multipleProductsRoute" class="media-link">
      <LikeShopItemImage
        :media="media"
        :object-fit="objectFit"
        :media-size="mediaSize"
        :default-alt-text="altText"
      />
    </router-link>
    <a
      v-else-if="media.productUrl"
      :href="media.productUrl"
      class="media-link"
      target="_blank"
      rel="noopener"
      @click="handleClick"
    >
      <LikeShopItemImage
        :media="media"
        :object-fit="objectFit"
        :media-size="mediaSize"
        :default-alt-text="altText"
      />
    </a>
    <LikeShopItemImage
      v-else
      :media="media"
      :object-fit="objectFit"
      :media-size="mediaSize"
      :default-alt-text="altText"
    />
  </article>
</template>

<script>
import { mapStores } from 'pinia';
import { useGalleryStore } from '@/stores/gallery';
import Icon from '@/components/Icon.vue';
import LikeShopItemImage from '@likeshop/components/LikeShopItemImage.vue';
import { imageApiFitValidation, imageApiSizeValidation } from '@/utils/imageApi';

export default {
  name: 'LikeShopItem',
  components: {
    Icon,
    LikeShopItemImage,
  },
  props: {
    media: { type: Object, default: null },
    objectFit: imageApiFitValidation,
    mediaSize: imageApiSizeValidation,
    defaultAltText: { type: String || null, default: null },
    callToAction: { type: String, default: '' },
  },
  computed: {
    ...mapStores(useGalleryStore),
    altText() {
      return this.defaultAltText ?? this.callToAction;
    },
    tiktokHandle() {
      return this.$route?.params?.tiktokHandle ?? null;
    },
    product() {
      return {
        id: this.media.productId,
        mediaProductId: this.media.mediaProductId,
      };
    },
    hasLinks() {
      return this.media.hasMultipleProducts || this.media.productUrl;
    },
    videoIconName() {
      if (this.media.type !== 'VIDEO') {
        return null;
      }
      return this.media.brandMediaType.includes('IGTV') ? 'igtv' : 'video';
    },
    multipleProductsRoute() {
      const name = this.tiktokHandle ? 'TiktokLikeShopMediaPopup' : 'LikeShopMediaPopup';
      return {
        name,
        params: { galleryMediaId: this.media.galleryMediaId },
      };
    },
  },
  methods: {
    handleClick(event) {
      this.galleryStore.trackProductLinkClick({
        event,
        galleryMediaId: this.media.galleryMediaId,
        product: this.product,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.clickable {
  cursor: pointer;
}

.media-container {
  background-color: $skeleton-loader-grey;
  position: relative;

  .media-badges {
    position: absolute;
    top: $space-4;
    right: $space-4;
  }
}

@supports (-moz-appearance: none) {
  .media-container:focus-within {
    outline: none;
    -webkit-box-shadow: 0 0 5px $link;
    box-shadow: 0 0 5px $link;
  }
}
</style>
