<template>
  <div class="guide" v-html="widgetsGuideMarkdown"></div>
</template>

<script>
import WidgetsGuide from '@/docs/WidgetsGuide.md';

export default {
  data() {
    return {
      widgetsGuideMarkdown: WidgetsGuide,
    };
  },
};
</script>

<style lang="scss" scoped>
.guide {
  margin: $space-40;

  ::v-deep {
    color: black;

    a {
      color: $link;
    }

    pre {
      margin: $space-8 0;
      padding: $space-8 $space-16;
      border: 1px solid $border;
      background-color: $background-grey;
      border-radius: $border-radius;
    }
  }
}
</style>
