/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'caret': {
    width: 11,
    height: 18,
    viewBox: '0 0 11 18',
    data: '<path pid="0" d="M2 17.415L.586 16l7-7-7-7L2 .586 10.414 9z" fill-rule="nonzero" _fill="#adadad"/>'
  }
})
