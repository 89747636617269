<template>
  <svgicon
    :name="name"
    :width="width ? width : size"
    :height="height ? height : size"
    :dir="dir"
    :style="style"
    :class="['icon', iconCustomClass]"
    @mouseover.native="isHovered = true"
    @mouseleave.native="isHovered = false"
  />
</template>

<script>
import svgicon from 'vue-svgicon'; // eslint-disable-line
import '@/assets/icons/compiled';

const SIZE_MAP = {
  tiny: '8',
  xxxsmall: '10',
  xxsmall: '12',
  xsmall: '16',
  small: '20',
  medium: '24',
  large: '28',
  xlarge: '32',
  xxlarge: '40',
  xxxlarge: '48',
};

export default {
  name: 'Icon',
  components: {
    svgicon,
  },
  props: {
    name: { type: String, required: true },
    color: { type: String, default: null },
    hoverColor: { type: String, default: null },
    activeColor: { type: String, default: null },
    isActive: { type: Boolean, default: false },
    tiny: { type: Boolean, default: false },
    xxxsmall: { type: Boolean, default: false },
    xxsmall: { type: Boolean, default: false },
    xsmall: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    medium: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    xlarge: { type: Boolean, default: false },
    xxlarge: { type: Boolean, default: false },
    xxxlarge: { type: Boolean, default: false },
    dir: { type: String, default: 'up' },
    height: { type: String, default: null },
    width: { type: String, default: null },
    customClass: { type: String, default: null },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    iconCustomClass() {
      return this.customClass ? this.customClass : '';
    },
    size() {
      const { tiny, xxxsmall, xxsmall, xsmall, small, medium, large, xlarge, xxlarge, xxxlarge } =
        this.$props;
      const sizes = {
        tiny,
        xxxsmall,
        xxsmall,
        xsmall,
        small,
        medium,
        large,
        xlarge,
        xxlarge,
        xxxlarge,
      };
      const size = Object.keys(sizes).find((key) => sizes[key]) || 'medium';
      return SIZE_MAP[size];
    },
    iconColor() {
      if (this.isHovered && this.hoverColor) {
        return this.hoverColor;
      }
      if (this.isActive && this.activeColor) {
        return this.activeColor;
      }
      return this.color;
    },
    style() {
      if (this.iconColor) {
        return { fill: this.iconColor };
      }
      return null;
    },
  },
  watch: {
    name(to, from) {
      // Workaround to remove the hover state because sometimes the mouseleave event does not get
      // triggered after we change the icon
      if (to !== null && to !== from) {
        this.isHovered = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  transition: $transition-all;
  fill: $secondary-text-icon;
}

.svg-up {
  /* default */
  transform: rotate(0deg);
}

.svg-right {
  transform: rotate(90deg);
}

.svg-down {
  transform: rotate(180deg);
}

.svg-left {
  transform: rotate(-90deg);
}
</style>
