<template>
  <header
    class="likeshop-header"
    :style="`justifyContent: ${showMobileView ? 'start' : 'center'}`"
    data-cy="header"
  >
    <a
      :href="profileUrl"
      target="_blank"
      rel="noopener"
      :aria-label="`Visit ${handle}'s ${platformType} page`"
      class="handle-link"
      data-cy="handle-link"
    >
      <Avatar :handle="handle" :image-src="avatarSrc" />
    </a>
  </header>
</template>

<script>
import Avatar from '@likeshop/components/Avatar.vue';
import { platformType } from '@/likeshop/constants';

export default {
  components: {
    Avatar,
  },
  props: {
    platformType: {
      type: String,
      default: platformType.INSTAGRAM,
      validator: (value) => value in platformType,
    },
    handle: {
      type: String,
      default: '',
    },
    avatarSrc: {
      type: String,
      default: '',
    },
  },
  computed: {
    profileUrl() {
      return this.platformType === platformType.TIKTOK
        ? `https://www.tiktok.com/@${this.handle}`
        : `https://instagram.com/${this.handle}`;
    },
    showMobileView() {
      return this.$mq.includes('mobile');
    },
  },
};
</script>

<style scoped lang="scss">
.likeshop-header {
  box-shadow: 0 0.5px 0 0 $border;
  display: flex;
  padding: $space-16;
}
</style>
