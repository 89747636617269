// Adapted from: https://github.com/madebyfabian/vue-focus-visible
// The original repo was including a bunch of extra code for no reason

let _el; // eslint-disable-line no-underscore-dangle

const defaultOptions = {
  defaultValue: true,
};

const setFocusVisible = (newVal) => {
  if (!(_el instanceof Element))
    throw new Error(
      'Cannot set the focus-visible value. Maybe you forgot to implement the v-focus-visible directive?'
    );

  _el.setAttribute('v-focus-visible', JSON.stringify(newVal));
};

const handleDirectiveCall = (el, options) => {
  _el = el;

  // Initially set default value.
  setFocusVisible(options.defaultValue);

  /**
   * Event listeners to change value.
   */
  el.addEventListener(
    'mousedown',
    (e) => {
      const { target } = e;
      const targetTagName = target.tagName.toLowerCase();

      if (target?.isContentEditable || targetTagName === 'textarea') return setFocusVisible(true);

      if (targetTagName === 'input')
        return setFocusVisible(
          !['radio', 'checkbox', 'range', 'button', 'file', 'reset', 'submit'].includes(target.type)
        );

      return setFocusVisible(false);
    },
    { passive: true }
  );

  el.addEventListener(
    'compositionstart',
    () => {
      setFocusVisible(true);
    },
    { passive: true }
  );

  el.addEventListener(
    'touchstart',
    () => {
      setFocusVisible(false);
    },
    { passive: true }
  );

  document.addEventListener(
    'keydown',
    () => {
      setFocusVisible(true);
    },
    { passive: true, useCapture: true }
  );
};

export const FocusVisible = {
  install(vueInstance, options) {
    const isVue3 = vueInstance.version.startsWith('3');

    const combinedOptions = { ...defaultOptions, ...options };

    if (isVue3) {
      vueInstance.config.globalProperties.$setFocusVisible = (newVal) => {
        setFocusVisible(newVal);
      };

      vueInstance.directive('focus-visible', {
        beforeMount: (el) => {
          handleDirectiveCall(el, combinedOptions);
        },
      });
    } else {
      vueInstance.prototype.$setFocusVisible = (newVal) => {
        vueInstance.nextTick(() => setFocusVisible(newVal));
      };

      vueInstance.directive('focus-visible', {
        bind: (el) => {
          handleDirectiveCall(el, combinedOptions);
        },
      });
    }
  },
};

// Automatic installation if Vue has been added to the global scope.
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(FocusVisible);
}

export default FocusVisible;
