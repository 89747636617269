<template>
  <section
    class="grid-layout-container"
    data-cy="GridLayoutContainer"
    :style="{
      'grid-template-columns': gridTemplateColumns,
      'grid-auto-rows': `${itemHeight}`,
      'grid-gap': gap,
      'max-width': maxWidth,
    }"
  >
    <slot default />
  </section>
</template>

<script>
export default {
  name: 'GridLayout',
  props: {
    columns: { type: [String, Number], default: '3' },
    gap: { type: String, default: '1rem' },
    itemHeight: { type: String, default: 'auto' },
    maxItemWidth: { type: String, default: '1fr' },
    minItemWidth: { type: String, default: '1rem' },
    maxWidth: { type: String, default: null },
  },
  computed: {
    gridTemplateColumns() {
      return Array(parseInt(this.columns, 10)).fill('1fr').join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-layout-container {
  display: grid;
  width: 100%;
  justify-content: center;
}
</style>
