<template>
  <section class="pinned-content-container" role="region" aria-label="Pinned Content">
    <GridLayout
      :columns="pinned.length"
      :gap="pinnedContentMediaGap"
      :style="{ 'margin-bottom': reactive.mediaGap }"
    >
      <LikeShopItem
        v-for="pin in pinned"
        :key="pin.id"
        :object-fit="'cover'"
        :media="pin"
        :media-size="mediaSize"
      />
    </GridLayout>
  </section>
</template>

<script>
import LikeShopItem from '@likeshop/components/LikeShopItem.vue';
import GridLayout from '@/components/GridLayout.vue';
import { mediaFormat } from '@/likeshop/constants';

export default {
  name: 'LikeshopPinnedContent',
  components: {
    LikeShopItem,
    GridLayout,
  },
  inject: ['reactive'],
  props: {
    gallery: {
      type: Object,
      default: () => {},
    },
    mediaFormat: {
      type: String,
      default: '',
    },
  },
  computed: {
    mediaSizeCountMap() {
      if (this.mediaFormat === mediaFormat.SQUARE) {
        return {
          1: {
            mobile: this.$config.imageSizes.w640h640,
            desktop: this.$config.imageSizes.w1280h1280,
          },
          2: {
            mobile: this.$config.imageSizes.w300h300,
            desktop: this.$config.imageSizes.w640h640,
          },
          3: {
            mobile: this.$config.imageSizes.w150h150,
            desktop: this.$config.imageSizes.w300h300,
          },
        };
      }
      return {
        1: {
          mobile: this.$config.imageSizes.w480h640,
          desktop: this.$config.imageSizes.w960h1280,
        },
        2: {
          mobile: this.$config.imageSizes.w300h400,
          desktop: this.$config.imageSizes.w480h640,
        },
        3: {
          mobile: this.$config.imageSizes.w150h200,
          desktop: this.$config.imageSizes.w300h400,
        },
      };
    },
    mediaSize() {
      const { mobile, desktop } = this.mediaSizeCountMap[this.gallery.pinned.length];
      if (this.$mq === 'mobile-portrait' || this.$mq === 'mobile-landscape') {
        return mobile;
      }
      return desktop;
    },
    pinnedContentMediaGap() {
      return `0px ${this.reactive.mediaGap}`;
    },
    pinned() {
      return this.gallery.pinned;
    },
  },
};
</script>
